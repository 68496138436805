var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"new-event-forms-view","fluid":"","tag":"section"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('material-card',{attrs:{"icon":"mdi-playlist-plus","icon-small":"","title":_vm.$t('task.new'),"color":"accent"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validateForm)}}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":"","name":_vm.$t('event.code')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.users,"item-value":"id","item-text":"item.username","label":_vm.$t('task.user'),"error-messages":errors,"outlined":"","return-value":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" - "+_vm._s(item.username)+" ")]}}],null,true),model:{value:(_vm.task.user),callback:function ($$v) {_vm.$set(_vm.task, "user", $$v)},expression:"task.user"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('task.description'),"outlined":""},model:{value:(_vm.task.description),callback:function ($$v) {_vm.$set(_vm.task, "description", $$v)},expression:"task.description"}})],1)],1),_c('v-btn',{attrs:{"color":"accent","min-width":"100","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("task.create"))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }