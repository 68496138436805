<template>
  <v-container
  id="new-event-forms-view"
  fluid
  tag="section"
  >
  <validation-observer v-slot="{ handleSubmit }">
    <material-card
      icon="mdi-playlist-plus"
      icon-small
      :title="$t('task.new')"
      color="accent"
    >
    <v-form
      @submit.prevent="handleSubmit(validateForm)">
    <v-container>
      <v-row>
        <v-col>
          <validation-provider
            v-slot="{ errors}"
            rules=""
            :name="$t('event.code')"
          >
            <v-autocomplete
              v-model="task.user"
              :items="users"
              item-value="id"
              item-text="item.username"
              :label="$t('task.user')"
              :error-messages="errors"
              outlined
              return-value
            >
              <template slot='selection' slot-scope='{ item }'>
                {{ item.first_name }} {{ item.last_name }}
              </template>
              <template slot='item' slot-scope='{ item }'>
                {{ item.first_name }} {{ item.last_name }} - {{ item.username }}
              </template>
            </v-autocomplete>
          </validation-provider>
        </v-col>
          <!-- <v-col cols='12' md='8'>
            <validation-provider
              v-slot="{ errors }"
              rules=""
              :name="$t('event.site')"
            >
            <v-autocomplete
              v-model="event.site"
              :items="sites"
              item-value="id"
              item-text="code_name"
              :loading="isLoadingSites"
              :label="$t('event.site')"
              :search-input.sync="searchSites"
              outlined
              :error-messages="errors"
              return-value
            >

            <template slot='selection' slot-scope='{ item }'>
              {{ item.code_name }} - {{ item.name }}
            </template>
            <template slot='item' slot-scope='{ item }'>
              {{ item.code_name }} - {{ item.name }}
            </template>
            </v-autocomplete>
            </validation-provider>
          </v-col>
          <v-col cols='12'>
            <v-text-field
              v-model="event.info"
              :label="$t('event.info')"
              outlined
            ></v-text-field>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="task.description"
              :label="$t('task.description')"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-btn
        color="accent"
        min-width="100"
        type="submit"
        >
        {{ $t("task.create") }}
      </v-btn>
    </v-container>
  </v-form>
  </material-card>
</validation-observer>

</v-container>
</template>

<script>
import TaskService from '@/services/task.service'
import UserService from '@/services/user.service'
// import Fuse from "fuse.js";

export default {
  name: 'NewTaskFormsView',

  $_veeValidate: {
      validator: 'new', // give me my own validator scope.
    },
    beforeMount() {
      console.log("EventId", this.$route.params.eventId)
      UserService.fetchGroup('internal_patrol')
      .then(res => res.data)
      .then(res => {
        this.users = res
      })
      .catch(err => {
        console.log(err)
      })
    },
    beforeDestroy () {
    },
    computed: {
    },
    data: () => ({
      users: [],
      task: {
        user: null,
        description: null,
      }
    }),
    watch: {
      },
      methods: {
        validateForm () {
          console.log("selected user", this.task.user)
          TaskService.create(this.$route.params.eventId, {
            assignees: [{
              user_id: this.task.user,
            }],
            task_type: 1,
            description: this.task.description,
            details: {}
          })
        },
      }
    }
    </script>
